@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #8cf1dd #ffdddd;
}

html::-webkit-scrollbar {
  width: 5px;
}

html::-webkit-scrollbar-thumb {
  background-color: #8cf1dd;
  border-radius: 5px;
}

html::-webkit-scrollbar-track {
  background-color: #ffdddd;
}

body {
  font-family: 'Poppins', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
